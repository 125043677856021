
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Open Sans";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: left;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #f1356d;
  align-items: left;
}
.navbar .links {
  margin-left: auto;
  color: #f1356d;
  padding-top: 8px;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.content {
  max-width: 600px;
  margin: 10px auto;
  padding: 5px;
  white-space: pre-wrap;
  padding-bottom: 25px;
}

/* css is the fucking worst */
img {
  height:auto;
  max-width:100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* blog previews / list */
.blog-preview {
  padding:  10px 16px;
  margin:  20px 0;
  border-bottom: 1px solid #fafafa;
}
.blog-preview:hover{
  box-shadow:  1px 3px 5px rgba(0,0,0,0.1);
}
.blog-preview h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px;
}
blockquote {
    color: #BAB6BA;
    margin: 0;
    padding-left: 3em;
    border-left: 0.3em #eee solid;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: auto;
  max-width: 100%;
  margin-bottom: 15px;
  background-color: transparent; /* Change the background-color of table here */
  text-align: left; /* Change the text-alignment of table here */
}

th {
  font-weight: bold;
  border: 1px solid #cccccc; /* Change the border-color of heading here */
  padding: 8px;
}

td {
  border: 1px solid #cccccc; /* Change the border-color of cells here */
  padding: 8px;
}

/* Stylized */

/* Adding Striped Effect for odd rows */

tr {
  background-color: transparent; /* Change the default background-color of rows here */
}

tr:nth-of-type(2n+1) {
  background-color: #eeeeee; /* Change the background-color of odd rows here */
}

tr th {
  background-color: #f1356d; /* Change the background-color of heading here */
  color: #eeeeee;
}

/* Adding Hover Effect for rows */

tr {
  -moz-transition: background-color 300ms ease-in-out 0s;
  -ms-transition: background-color 300ms ease-in-out 0s;
  -o-transition: background-color 300ms ease-in-out 0s;
  -webkit-transition: background-color 300ms ease-in-out 0s;
  transition: background-color 300ms ease-in-out 0s;
}

/* Removing left and right border of rows for modern UIs */

tr {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  font-size: smaller
}

th, td {
  border: none;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
}
